import { InferGetStaticPropsType } from "next";
import type { NextSeoProps } from "next-seo";
import dynamic from "next/dynamic";
import Image from "next/image";
import { useRouter } from "next/router";
import { ReactNode } from "react";

import { AppLayout } from "shared/Layout";
import { fetchSettings } from "shared/SettingsProvider/settings.helper";
import { BestSellersCatalog } from "shared/catalog/BestSellersCatalog";
import { BestSellersContextProvider } from "shared/catalog/BestSellersCatalog/BestSellersContext";
import { getBestSellerEntries } from "shared/catalog/BestSellersCatalog/best-sellers-catalog.helper";
import { Button } from "ui/Button";
import { CACHE_ONE_WEEK } from "utils/config.utils";
import { withApp } from "utils/with-app.utils";
import { fetchTranslations, useI18n } from "utils/with-i18n.utils";
import { withSeo } from "utils/with-seo.utils";

const Marquee = dynamic(() => import("react-fast-marquee"));

const ErrorPage = ({
  bestSellersEntries,
}: InferGetStaticPropsType<typeof getStaticProps>) => {
  const i18n = useI18n();
  const { replace } = useRouter();

  const handleGoToHome = () => replace("/");
  const handleGoToCatalog = () => replace("/smartphone");

  return (
    <>
      <div className="relative h-72 lg:h-[30rem]">
        <Image
          alt="Error page banner"
          src="/images/error/banner.webp"
          height={802}
          width={3024}
          layout="fill"
          objectFit="cover"
        />
        <div className="absolute bottom-1/2 w-full">
          <Marquee
            speed={100}
            gradient={false}
            className="text-6xl font-bold text-white"
          >
            <p className="mx-3">{i18n.t("errorPage.title")}</p>
            <p className="mx-3">{i18n.t("errorPage.title")}</p>
            <p className="mx-3">{i18n.t("errorPage.title")}</p>
            <p className="mx-3">{i18n.t("errorPage.title")}</p>
          </Marquee>
        </div>
        <div className="absolute inset-x-0 bottom-1/4 mx-auto w-fit">
          <Button onClick={handleGoToHome}>{i18n.t("errorPage.cta")}</Button>
        </div>
      </div>
      <div className="flex flex-col items-center py-20 mx-auto">
        <h2 className="self-center mt-4 text-5xl font-medium text-center">
          {i18n.t("errorPage.bestSellers")}
        </h2>

        <div className="flex flex-wrap gap-4 justify-center px-5 my-8 w-full max-w-6xl lg:my-14">
          <BestSellersContextProvider entries={bestSellersEntries}>
            <BestSellersCatalog />
          </BestSellersContextProvider>
        </div>

        <Button
          alternative="secondary"
          onClick={handleGoToCatalog}
        >
          {i18n.t("errorPage.otherDevices")}
        </Button>
      </div>
    </>
  );
};

ErrorPage.getLayout = (page: ReactNode, pageProps: any) => (
  <AppLayout {...pageProps}>{page}</AppLayout>
);

export async function getStaticProps() {
  const seo: NextSeoProps = { noindex: true };

  const bestSellersEntries = await getBestSellerEntries();
  const translations = await fetchTranslations("catalog");

  return {
    props: {
      bestSellersEntries,
      translations,
      seo,
      settings: await fetchSettings(),
    },
    revalidate: CACHE_ONE_WEEK,
  };
}

export default withApp(ErrorPage, [withSeo]);
